import axios from 'axios';
import qs from 'qs';
import Services from './services';

function paramSerialise(params) {
    // Qs is already included in the Axios package
    return qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
    });
}

export default class NetService {
    static prepareUri(uri, params) {
        let re = /\{([\w\-]+)\}/g;
        let match = re.exec(uri);
        let keys = new Set();
        while (match) {
            keys.add(match[1]);
            match = re.exec(uri);
        }
        for (let key of keys) {
            uri = uri.replace(new RegExp(`\{${key}\}`), params[key]);
            delete params[key];
        }
        return { uri, params };
    }

    params(method, uri, params = {}, data = {}, options = {}) {
        let store = Services.store();
        let { uri: newUri, params: newParams } = NetService.prepareUri(uri, params);
        let config = Object.assign(
            {
                url: newUri,
                method: method,
                params: newParams,
                // mode: 'cors',
                // withCredentials: false,
                headers: {
                    Accept: 'application/json',
                },
                paramsSerializer: paramSerialise,
            },
            options
        );
        if (['post', 'put', 'patch'].includes(method)) {
            if (data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
                config.data = data;
            } else {
                config.headers['Content-Type'] = 'application/json';
                config.data = JSON.stringify(data);
            }
        }

        if (store.state.env.token) {
            config.headers['Authorization'] = `Bearer ${store.state.env.token}`;
        }

        return config;
    }

    get(uri, params = {}, options = {}, disableErrorAlert = false) {
        return this.request(this.params('get', uri, params, {}, options), disableErrorAlert);
    }

    post(uri, params = {}, data = {}, options = {}, disableErrorAlert = false) {
        return this.request(this.params('post', uri, params, data, options), disableErrorAlert);
    }

    put(uri, params = {}, data = {}, options = {}, disableErrorAlert = false) {
        return this.request(this.params('put', uri, params, data, options), disableErrorAlert);
    }

    patch(uri, params = {}, data = {}, options = {}, disableErrorAlert = false) {
        return this.request(this.params('patch', uri, params, data, options), disableErrorAlert);
    }

    delete(uri, params = {}, options = {}, disableErrorAlert = false) {
        return this.request(this.params('delete', uri, params, {}, options), disableErrorAlert);
    }

    request(params, disableErrorAlert = false) {
        return axios
            .request(params)
            .then(resp => {
                if (resp.status >= 200 && resp.status < 300) {
                    return resp.data;
                }
            })
            .catch(({ request, response, message, config }) => {
                let errorMsg = '';
                if (response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(response.data);
                    console.log(response.status);
                    console.log(response.headers);
                    if (response.data.errors) {
                        errorMsg = response.data.errors.map(e => e.message).join(',');
                    } else if (response.data.message) {
                        errorMsg = response.data.message;
                    } else {
                        errorMsg = 'Ошибка без текста, сообщите разработчикам.';
                    }
                } else if (request) {
                    // The request was made but no response was received
                    // `error.response` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', message);
                }

                console.log(config);

                if (!disableErrorAlert && errorMsg) {
                    Services.msg(errorMsg, 'danger');
                }

                return Promise.reject(errorMsg);
            });
    }
}

import Services from '../../../scripts/services/services';

export const NAMESPACE = 'categories';

export const SET_PAGE = 'set_page';

export const GET_LIST = 'get_list';
export const GET_PARENT_LIST = 'get_parent_list';
export const GET_PAGE_NUMBER = 'get_page_number';
export const GET_TOTAL = 'get_total';
export const GET_PAGE_SIZE = 'get_page_size';
export const GET_NUM_PAGES = 'get_num_pages';

export const ACT_LOAD_PAGE = 'act_load_page';
export const ACT_SAVE_CATEGORY = 'act_save_category';
export const ACT_DELETE_CATEGORY = 'act_delete_category';

const PAGE_SIZE = 10;

export default {
    name: NAMESPACE,
    namespaced: true,
    state: {
        list: [],
        parentCategories: [],
        page: 1,
        total: 0,
    },

    mutations: {
        [SET_PAGE](state, {list, parentCategories, page, total}) {
            state.list = list;
            state.parentCategories = parentCategories;
            state.page = page;
            state.total = total;
        }
    },
    getters: {
        [GET_LIST]: state => state.list,
        [GET_PARENT_LIST]: state => state.parentCategories,
        [GET_PAGE_NUMBER]: state => state.page,
        [GET_TOTAL]: state => state.total,
        [GET_PAGE_SIZE]: () => PAGE_SIZE,
        [GET_NUM_PAGES]: state => Math.ceil(state.total / PAGE_SIZE)
    },
    actions: {
        [ACT_LOAD_PAGE]({commit, rootGetters}, {page}) {
            Services.showLoader();
            return Services.net().get(rootGetters.getRoute('category.listPage'), {page})
                .then(data => {
                    commit(SET_PAGE, {
                        list: data.categories,
                        parentCategories: data.parentCategories,
                        total: data.total,
                        page
                    })
                }).finally(() => {
                Services.hideLoader();
            });
        },
        [ACT_SAVE_CATEGORY]({rootGetters}, {id, category}) {
            return Services.net().post(rootGetters.getRoute('category.save'), {}, {id, category});
        },
        [ACT_DELETE_CATEGORY]({rootGetters}, {ids}) {
            return Services.net().post(rootGetters.getRoute('category.delete'), {}, {ids});
        }
    }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-2 my-1", class: { active: _vm.item.active } },
    [
      _vm.item.items
        ? _c(
            "div",
            {
              staticClass: "cursor-pointer",
              on: {
                click: function($event) {
                  return _vm.toggleItem()
                }
              }
            },
            [
              _c(
                "span",
                { style: _vm.indent },
                [
                  _vm.depth === 1 && _vm.item.icon
                    ? _c("fa-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: _vm.item.icon }
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n            " + _vm._s(_vm.item.title) + "\n            "
                  ),
                  _c("fa-icon", {
                    staticClass: "float-right shevron",
                    attrs: { icon: _vm.opened ? "chevron-up" : "chevron-down" }
                  })
                ],
                1
              )
            ]
          )
        : _c("div", { style: _vm.indent }, [
            _c(
              "a",
              {
                staticClass: "text-muted main-menu-link",
                attrs: { href: _vm.item.route }
              },
              [_vm._v(_vm._s(_vm.item.title))]
            )
          ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.item.items && _vm.opened
          ? _c(
              "div",
              { staticClass: "mt-2" },
              _vm._l(_vm.item.items, function(subitem, index) {
                return _c("menu-item", {
                  key: index,
                  attrs: { item: subitem, depth: _vm.depth + 1 }
                })
              }),
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
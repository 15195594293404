import Services from '../../../scripts/services/services';

export const NAMESPACE = 'bonus';

export const GET_LIST = 'get_list';

export const ACT_CREATE_CARD = 'act_create_card';
export const ACT_UPDATE_CARD = 'act_update_card';
export const ACT_DELETE_CARD = 'act_delete_card';

export default {
    name: NAMESPACE,
    namespaced: true,
    state: {
        bonusCards: [],
        page: 1,
        total: 0
    },

    mutations: {
        // [SET_PAGE](state, {list, page, total}) {
        //     state.cardsList = list;
        //     state.page = page;
        //     state.total = total;
        // }
    },
    getters: {
        // [GET_CARDS_LIST]: state => state.bonusCards,
        // [GET_PAGE]: state => state.page,
        // [GET_TOTAL]: state => state.total,
    },
    actions: {
        [ACT_CREATE_CARD]({rootGetters}, {newCard}) {
            return Services.post(rootGetters('bonus-card.save'), {}, {newCard});
        },

        [ACT_UPDATE_CARD]({rootGetters}, {id}, {updateCard}) {
            return Services.put(rootGetters('bonus-card.update'), {id}, {updateCard});
        },

        [ACT_DELETE_CARD]({rootGetters}, {id}) {
            return Services.net().delete(rootGetters.getRoute('bonus-card.delete'), {}, {id});
        },
    }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fake-vue-body" },
    [
      _c("div", { staticClass: "d-flex flex-row h-100" }, [
        !_vm.user.isGuest
          ? _c(
              "div",
              { staticClass: "bg-white", staticStyle: { width: "250px" } },
              [_c("MainMenu")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-grow-1" },
          [
            _c("LayoutHeader", { attrs: { back: _vm.back } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "container-fluid px-3 pb-5" },
              [
                !_vm.hideTitle
                  ? _c("h1", { staticClass: "my-3" }, [
                      _vm._v(_vm._s(_vm.title))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("default"),
                _vm._v(" "),
                _c("div", { staticClass: "clearfix" })
              ],
              2
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("modal-message"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loaderShow,
              expression: "loaderShow"
            }
          ],
          attrs: { id: "preloader" }
        },
        [_c("div", { attrs: { id: "loader" } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.user.isGuest
    ? _c(
        "nav",
        {
          staticClass: "navbar navbar-light bg-white d-flex",
          class: _vm.back ? "justify-content-between" : "justify-content-end"
        },
        [
          _vm.back
            ? _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary",
                  on: { click: _vm.goBack }
                },
                [
                  _c("fa-icon", { attrs: { icon: "arrow-left" } }),
                  _vm._v(" Назад\n        ")
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-link text-secondary",
              on: { click: _vm.logout }
            },
            [
              _c("fa-icon", { attrs: { icon: "sign-out-alt" } }),
              _vm._v("\n            Выйти\n        ")
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <nav class="sidebar">
        <div class="p-4">
            <div class="d-flex align-items-center">
                <img src="/assets/images/logo.png" width="70"/>
                <small class="text-muted align-middle">Система управления</small>
            </div>
        </div>

        <menu-item v-for="(item, index) in menu" :item="item" :key="index"></menu-item>
    </nav>
</template>

<script>
import '../../../images/logo.png';
import MenuItem from './menu-item.vue';

export default {
    name: 'main-menu',
    components: {
        MenuItem
    },
    computed: {
        menu() {
            return this.$store.state.layout.menu;
        },
    },
};
</script>
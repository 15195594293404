/** ************************************* svg ************************************************************************* */
/** ************************************* css ************************************************************************* */
import '../styles/customMedia.css';
import '../styles/customProperties.css';
import '../styles/customSelectors.css';
import '../styles/app.css';
import '../styles/validation-error.css';

import 'focus-visible';
import './mask';
import './validation';
import 'lazysizes';

import Vue from 'vue';
import LayoutMain from '../vue/components/layout/Main.vue';
/** ************************************* css external **************************************************************** */
import '../styles/bootstrap/font.css';
import '../styles/bootstrap/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'vue-multiselect/dist/vue-multiselect.min.css'

/** ************************************* /svg ************************************************************************* */
/** ************************************* /css external **************************************************************** */

/** ************************************* /css ************************************************************************* */

/** ************************************* js ************************************************************************* */

/** ************************************* /js ************************************************************************* */

Vue.component('layout-main', LayoutMain);

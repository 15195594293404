<template>
    <nav class="navbar navbar-light bg-white d-flex" :class="back ? 'justify-content-between' : 'justify-content-end'" v-if="!user.isGuest">
        <button v-if="back" class="btn btn-outline-secondary" @click="goBack">
            <fa-icon icon="arrow-left"/> Назад
        </button>

<!--        <notifications/>-->
<!--        <communication-chats-unread/>-->

        <button @click="logout" class="btn btn-link text-secondary">
            <fa-icon icon="sign-out-alt"/>
            Выйти
        </button>
    </nav>
</template>

<script>
import Services from '../../../../scripts/services/services';
import modalMixin from '../../../mixins/modal.js';
import CommunicationChatsUnread from './communication-chats-unread.vue';
import Notifications from './notifications.vue';

export default {
    name: 'layout-header',
    components: {Notifications, CommunicationChatsUnread},
    mixins: [modalMixin],
    props: {
        back: String,
    },
    data() {
        return {};
    },
    methods: {
        goBack() {
            if (this.back) {
                location.href = this.back;
            }
        },
        logout() {
            Services.net().post(this.route('logout')).then(() => {
                window.location.href = this.route('page.login');
            }, () => {
                this.showMessageBox({title: 'Ошибка', text: 'Произошла ошибка. Попробуйте позже.'})
            });
        }
    },
};
</script>
<style>

</style>

import { render, staticRenderFns } from "./order-status.vue?vue&type=template&id=c66dd350&scoped=true&"
import script from "./order-status.vue?vue&type=script&lang=js&"
export * from "./order-status.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c66dd350",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/admin/master/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('c66dd350', component.options)
    } else {
      api.reload('c66dd350', component.options)
    }
    module.hot.accept("./order-status.vue?vue&type=template&id=c66dd350&scoped=true&", function () {
      api.rerender('c66dd350', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/components/order-status.vue"
export default component.exports
<template>
    <div class="fake-vue-body">
        <div class="d-flex flex-row h-100">
            <div class="bg-white" style="width: 250px;" v-if="!user.isGuest">
                <MainMenu></MainMenu>
            </div>

            <div class="flex-grow-1">
                <LayoutHeader :back="back"></LayoutHeader>
                <div class="container-fluid px-3 pb-5">
                    <h1 class="my-3" v-if="!hideTitle">{{ title }}</h1>
                    <slot></slot>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>

        <modal-message/>

        <div id="preloader" v-show="loaderShow"><div id="loader"></div></div>
    </div>
</template>

<script>
import LayoutHeader from './parts/layout-header.vue';
import ModalMessage from '../modal-message/modal-message.vue';
import MainMenu from '../main-menu/main-menu.vue';
import Services from '../../../scripts/services/services.js';

export default {
    name: 'layout-main',
    props: {
        back: String,
        hideTitle: { type: Boolean, default: false },
    },
    components: {
        LayoutHeader,
        ModalMessage,
        MainMenu,
    },
    computed: {
        title() {
            return this.$store.state.title;
        },
        loaderShow() {
            return Services.store().state.loaderShow;
        }
    },
    created() {
        Services.event().$on('toast', ({text, variant}) => {
            this.$bvToast.toast(text, {
                title: 'Сообщение',
                variant: variant || 'success',
                //toaster: 'b-toaster-top-center',
                solid: true,
                autoHideDelay: 5000,
            });
        });
    }
};
</script>

<style scoped>
    .fake-vue-body {
        height: 100%;
    }
    .breadcrumbs {
        margin-top: 15px;
    }

    /* ============= Loader ===================== */
    #preloader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background: rgba(255, 255, 255, 0.6);
    }
    #loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #000000;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    #loader:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #000000;
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
    }
    #loader:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #000000;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }
    @-webkit-keyframes spin {
        0%   {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes spin {
        0%   {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>

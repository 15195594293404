<template>
    <div class="py-2 my-1" :class="{active: item.active}">
        <div v-if="item.items" @click="toggleItem()" class="cursor-pointer">
            <span :style="indent">
                <fa-icon :icon="item.icon" v-if="depth === 1 && item.icon" class="mr-2"/>
                {{ item.title }}
                <fa-icon :icon="opened ? 'chevron-up' : 'chevron-down'" class="float-right shevron"/>
            </span>
        </div>
        <div v-else :style="indent">
            <a :href="item.route" class="text-muted main-menu-link">{{ item.title }}</a>
        </div>

        <transition name="slide">
            <div v-if="item.items && opened" class="mt-2">
                <menu-item v-for="(subitem, index) in item.items" :item="subitem" :key="index" :depth="depth + 1"></menu-item>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "menu-item",
    props: {
        item: {},
        depth: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            toggle: null,
        };
    },
    methods: {
        checkItemsHasActive(item) {
            let result = false;
            if (item.active) {
                result = true;
            } else {
                if (item.items) {
                    for (let subitem of item.items) {
                        if (this.checkItemsHasActive(subitem)) {
                            result = true;
                            break;
                        }
                    }
                }
            }
            return result;
        },
        toggleItem() {
            if (this.item.route) {
                return;
            } else {
                if (this.toggle === null) {
                    this.toggle = !this.active;
                } else {
                    this.toggle = !this.toggle;
                }
            }
        },
    },
    computed: {
        indent() {
            let padding = 16 * this.depth;
            let width;
            if (this.depth === 2) {
                padding = 43;
                width = 250;
            }
            return {
                'padding-left': `${padding}px`,
                'max-width': `${width}px`,
            };
        },
        active() {
            return this.checkItemsHasActive(this.item);
        },
        opened() {
            if (this.toggle === null) {
                return this.active;
            } else {
                return this.toggle;
            }
        }
    }
}
</script>

<style scoped>
    .active {
        background: #0D7AD9;
    }
    .active a {
        color: white !important;
    }
    .shevron {
        position: relative;
        top: 4px;
        right: 16px;
    }
    .main-menu-link {
        padding-right: 5px;
    }
</style>